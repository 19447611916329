import { NewOfferDTO } from '@/dtos/offer'
import { Offer, NewDocument, NewAudio, NewFounder } from '@/interfaces/offer'
import axios, { AxiosInstance, AxiosResponse } from 'axios'
import sanitize from 'sanitize-filename'
import { applySerializeInterceptors } from './interceptors/serialize'

function sanitizeFileName(filename) {
  const extension = filename.split('.').pop()
  const trimmed = filename.trim().replace(/\s+/g, '-')
  const baseFilename = sanitize(trimmed.substr(0, trimmed.lastIndexOf('.')) || trimmed)
  const now = Date.now()

  return `${baseFilename}-${now}.${extension}`
}

interface StorageUploadData {
  bucket: string;
  pasta: string;
  nome: string;
  tipo: string;
  arquivo: File;
}

interface DocumentMetadata {
  id: string;
  url: string;
  tipo: string;
  nome: string;
  bucket_name: string;
  blob_name: string;
}

export default class OfferService {
  public INVESTMENT_ENDPOINT = process.env.VUE_APP_INVESTMENT_ENDPOINT
  public OFFER_ENDPOINT = process.env.VUE_APP_OFFER_ENDPOINT
  public QUOTAS_ENDPOINT = process.env.VUE_APP_QUOTAS_ENDPOINT

  private client: AxiosInstance

  constructor() {
    this.client = axios
  }

  all(): Promise<Offer[]> {
    return this.client
      .get<Offer[]>(`${this.OFFER_ENDPOINT}/oferta/lista`)
      .then((response) => response.data)
  }

  findByURL(url): Promise<Offer> {
    return this.client
      .get<Offer>(`${this.OFFER_ENDPOINT}/oferta/busca/${url}`)
      .then((response) => response.data)
  }

  resume(offerUrl) {
    return this.client
      .get(`${this.INVESTMENT_ENDPOINT}/investimento/dashboard/consolidado/lista/${offerUrl}`)
      .then(response => response.data)
  }

  update(form) {
    const endpoint = `${this.OFFER_ENDPOINT}/oferta/atualiza/${form.url_oferta}`

    return this.client
      .put(endpoint, { ...form })
      .then((response) => response.data)
  }

  async save(newOffer: NewOfferDTO) {
    const client = axios.create()
    applySerializeInterceptors(client)

    const endpoint = `${this.OFFER_ENDPOINT}/oferta/nova`

    try {
      const { data } = await client.post(endpoint, { ...newOffer })
      return data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorData = error.response?.data
        return Promise.reject(errorData)
      }
    }
  }

  storageUpload(payload: StorageUploadData) {
    const endpoint = 'https://us-central1-startmeup-218419.cloudfunctions.net/storage-upload'

    interface FormDataB extends FormData {
      _boundary: any;
    }

    const data: FormDataB = new FormData() as FormDataB

    data.append('bucket', payload.bucket)
    data.append('pasta', payload.pasta)
    data.append('nome', payload.nome)
    data.append('tipo', payload.tipo)
    data.append('arquivo', payload.arquivo)

    const headers = {
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`
    }

    return axios
      .post(endpoint, data, { headers })
      .then(response => response.data)
  }

  uploadImage(file: File): Promise<string> {
    interface FormDataB extends FormData {
      _boundary: any;
    }

    const data: FormDataB = new FormData() as FormDataB

    data.append('photo', file)
    data.append('nome', file.name)

    const endpoint =
      'https://us-central1-startmeup-218419.cloudfunctions.net/image-service'

    return this.client
      .post(endpoint, data, {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`
        }
      })
      .then((response) => {
        return response.data
      })
  }

  saveRI(RI, url) {
    const endpoint = `${this.OFFER_ENDPOINT}/oferta/ri/novo/${url}`

    return this.client
      .post(endpoint, { ...RI })
      .then((response) => response.data)
  }

  updateRI(RI, url) {
    const endpoint = `${this.OFFER_ENDPOINT}/oferta/ri/atualiza/${url}`

    return this.client
      .put(endpoint, { ...RI })
      .then((response) => response.data)
  }

  businessSegments() {
    return this.client
      .get(`${this.OFFER_ENDPOINT}/oferta/setores_atuacao`)
      .then(response => response.data)
  }

  newBusinessSegment(form) {
    return this.client
      .post(`${this.OFFER_ENDPOINT}/oferta/setores_atuacao`, form)
      .then(response => response.data)
  }

  updateBusinessSegment(segment) {
    return this.client
      .put(`${this.OFFER_ENDPOINT}/oferta/setores_atuacao/${segment.id}`, segment)
      .then(response => response.data)
  }

  tags() {
    return this.client
      .get(`${this.OFFER_ENDPOINT}/oferta/tags`)
      .then(response => response.data)
  }

  newTag(form) {
    return this.client
      .post(`${this.OFFER_ENDPOINT}/oferta/tags`, form)
      .then(response => response.data)
  }

  updateTag(segment) {
    return this.client
      .put(`${this.OFFER_ENDPOINT}/oferta/tags/${segment.id}`, segment)
      .then(response => response.data)
  }

  newDocument(documentForm: NewDocument, urlOferta: string) {
    const endpoint = `${this.OFFER_ENDPOINT}/oferta/documento/${urlOferta}`

    interface FormDataB extends FormData {
      _boundary: any;
    }

    const data: FormDataB = new FormData() as FormDataB

    documentForm.documento.nome_arquivo = sanitizeFileName(documentForm.documento.nome_arquivo)
    documentForm.documento.bucket = 'oferta-assets'
    documentForm.documento.pasta = `${urlOferta}/documentos`

    data.append('arquivo', documentForm.arquivo)
    data.append('documento', JSON.stringify(documentForm.documento))

    return this.client
      .post(endpoint, data,
        {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`
          }
        })
      .then((response) => response.data)
  }

  updateDocument(documentForm: NewDocument, urlOferta: string) {
    const endpoint = `${this.OFFER_ENDPOINT}/oferta/documento/${documentForm.documento.id}`

    interface FormDataB extends FormData {
      _boundary: any;
    }

    const data: FormDataB = new FormData() as FormDataB

    if (documentForm.arquivo) {
      data.append('arquivo', documentForm.arquivo)
      documentForm.documento.nome_arquivo = sanitizeFileName(documentForm.documento.nome_arquivo)
      documentForm.documento.bucket = 'oferta-assets'
      documentForm.documento.pasta = `${urlOferta}/documentos`
    }

    data.append('documento', JSON.stringify(documentForm.documento))

    return this.client
      .put(endpoint, data,
        {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`
          }
        })
      .then((response) => response.data)
  }

  updateDocumentMetadata(document: DocumentMetadata) {
    const endpoint = `${this.OFFER_ENDPOINT}/oferta/documento/${document.id}/metadados`

    return this.client
      .put(endpoint, document)
      .then((response) => response.data)
  }

  deleteDocument(documentId: string) {
    const endpoint = `${this.OFFER_ENDPOINT}/oferta/documento/${documentId}`

    return this.client
      .delete(endpoint)
      .then((response: AxiosResponse) => response && response.data)
  }

  newAudio(audioForm: NewAudio, urlOferta: string) {
    const endpoint = `${this.OFFER_ENDPOINT}/oferta/audio/${urlOferta}`

    interface FormDataB extends FormData {
      _boundary: any;
    }

    const data: FormDataB = new FormData() as FormDataB

    audioForm.audio.nome_arquivo = sanitizeFileName(audioForm.audio.nome_arquivo)
    audioForm.audio.bucket = 'oferta-assets'
    audioForm.audio.pasta = `${urlOferta}/audios`

    data.append('arquivo', audioForm.arquivo)
    data.append('audio', JSON.stringify(audioForm.audio))

    return this.client
      .post(endpoint, data,
        {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`
          }
        })
      .then((response) => response.data)
  }

  updateAudio(audioForm: NewAudio, urlOferta: string) {
    const endpoint = `${this.OFFER_ENDPOINT}/oferta/audio/${audioForm.audio.id}`

    interface FormDataB extends FormData {
      _boundary: any;
    }

    const data: FormDataB = new FormData() as FormDataB

    if (audioForm.arquivo) {
      data.append('arquivo', audioForm.arquivo)
      audioForm.audio.nome_arquivo = sanitizeFileName(audioForm.audio.nome_arquivo)
      audioForm.audio.bucket = 'oferta-assets'
      audioForm.audio.pasta = `${urlOferta}/audios`
    }

    data.append('audio', JSON.stringify(audioForm.audio))

    return this.client
      .put(endpoint, data,
        {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`
          }
        })
      .then((response) => response.data)
  }

  newFounder(founderForm: NewFounder, offerUrl: string) {
    const endpoint = `${this.OFFER_ENDPOINT}/oferta/fundador/${offerUrl}`

    interface FormDataB extends FormData {
      _boundary: any;
    }

    const data: FormDataB = new FormData() as FormDataB

    founderForm.fundador.nome_arquivo = sanitizeFileName(founderForm.fundador.nome_arquivo)
    founderForm.fundador.bucket = 'oferta-assets'
    founderForm.fundador.pasta = `${offerUrl}/fundadores`

    data.append('arquivo', founderForm.arquivo)
    data.append('fundador', JSON.stringify(founderForm.fundador))

    return this.client
      .post(endpoint, data,
        {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`
          }
        })
      .then((response) => response.data)
  }

  updateFounder(founderForm: NewFounder, offerUrl: string) {
    const endpoint = `${this.OFFER_ENDPOINT}/oferta/fundador/${founderForm.fundador.id}`

    interface FormDataB extends FormData {
      _boundary: any;
    }

    const data: FormDataB = new FormData() as FormDataB

    if (founderForm.arquivo) {
      data.append('arquivo', founderForm.arquivo)
      founderForm.fundador.nome_arquivo = sanitizeFileName(founderForm.fundador.nome_arquivo)
      founderForm.fundador.bucket = 'oferta-assets'
      founderForm.fundador.pasta = `${offerUrl}/fundadores`
    }

    data.append('fundador', JSON.stringify(founderForm.fundador))

    return this.client
      .put(endpoint, data,
        {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`
          }
        })
      .then((response) => response.data)
  }

  deleteFounder(founderId: string) {
    const endpoint = `${this.OFFER_ENDPOINT}/oferta/fundador/${founderId}`

    return this.client
      .delete(endpoint)
      .then((response) => response.data)
  }

  convertInvestmentsToQuotas(offerUrl: string) {
    return this.client
      .post(`${this.QUOTAS_ENDPOINT}/cotas/conciliacao`, { url_oferta: offerUrl })
      .then(response => response.data)
  }

  quotasByOffer(offerUrl: string) {
    return this.client
      .get(`${this.QUOTAS_ENDPOINT}/cotas/lista/cpfOuOferta`, { params: { oferta: offerUrl } })
      .then(response => response.data)
  }
}
