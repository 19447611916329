import { AxiosResponse, AxiosRequestConfig, AxiosInstance } from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'

export const serializeResponse = (response: AxiosResponse) => {
  if (
    response.data &&
    response.headers['content-type'] === 'application/json'
  ) {
    response.data = camelizeKeys(response.data)
  }

  return response
}

export const serializeRequest = (config: AxiosRequestConfig) => {
  const newConfig = { ...config }

  if (newConfig.headers['Content-Type'] === 'multipart/form-data') return newConfig

  if (config.params) {
    newConfig.params = decamelizeKeys(config.params)
  }

  if (config.data) {
    newConfig.data = decamelizeKeys(config.data)
  }

  return newConfig
}

export const applySerializeInterceptors = (client: AxiosInstance) => {
  client.interceptors.response.use(serializeResponse)
  client.interceptors.request.use(serializeRequest)
}
